import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Header from '../components/header'
import Footer from '../components/footer'

require('../scss/main.scss')

class DefaultLayout extends React.Component {
  render() {
    return (
        <>
          <Header siteTitle="KazikKoduje" />
            <div className="site">
                {this.props.children}
            </div>
          <Footer />
        </>
    )
  }
}

// DefaultLayout.propTypes = {
//   location: PropTypes.object.isRequired,
// }

export default DefaultLayout

